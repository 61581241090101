import * as React from 'react';
import { Moment, default as moment } from 'moment';
import { DataNodePropDate } from '../../../models/DataNodeUtils';
import { DESCRIPTOR_TYPE_PROP } from '@simosol/iptim-data-model';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { StaticDatePicker } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import Button from '../../../uicomponents/Button';
import { LangKey } from '../../../LangKey';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: 'min-content',
    minWidth: 'min-content',
  },
  contentRoot: {
    padding: theme.spacing(0),
    '&:first-child': {
      padding: theme.spacing(0),
    },
  },
}));

type DatePropEditorProps = {
  item: DataNodePropDate;
  open: boolean;
  onClose: () => void;
};

const EditorDate = (props: DatePropEditorProps) => {
  const { item, open, onClose } = props;
  const styles = useStyles();

  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(moment(item.value));

  const onChange = (date: Date | null) => {
    setSelectedDate(moment(date));
  };

  const onAccept = () => {
    const mDate = selectedDate as unknown as Moment;
    item.value = {
      month: mDate.month(),
      date: mDate.date(),
      year: mDate.year(),
      [DESCRIPTOR_TYPE_PROP]: 'iptim.amf.SimpleDate',
    };
    onClose();
  };

  const onClear = () => {
    item.value = undefined;
    setSelectedDate(null);
    onClose();
  };

  return (
    <Dialog open={open} classes={{ paper: styles.root }}>
      <DialogContent classes={{ root: styles.contentRoot }}>
        <StaticDatePicker
          renderInput={props => <TextField {...props} style={{ display: 'none' }}/>}
          open={open}
          value={selectedDate?.toDate()}
          views={['year', 'month', 'day']}
          onChange={onChange}
          displayStaticWrapperAs="mobile"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClear} color="secondary">{LangKey.CommonClear}</Button>
        <Button onClick={onClose} color={'inherit'}>{LangKey.CommonCancel}</Button>
        <Button onClick={onAccept} color="primary">{LangKey.CommonOk}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditorDate;
