import { makeObservable, observable } from 'mobx';
import LocalStorage from './LocalStorage';
import { LangKey } from '../LangKey';

export type UnitAge = 'month' | 'year';
export type UnitSettings = { key: 'month', value: string } | { key: 'year', value: string };

export default class Settings {
  @observable
  unitAge: 'month' | 'year';
  allUnitAges: UnitSettings[] = [
    { key: 'month', value: LangKey.SettingsAgeUnitMonth },
    { key: 'year', value: LangKey.SettingsAgeUnitYear },
  ];
  @observable
  allStands: boolean;

  private _localStorage: LocalStorage;

  constructor(localStorage: LocalStorage) {
    makeObservable(this);
    this._localStorage = localStorage;
    this.unitAge = this._localStorage.getValue('settingsUnitAge') as UnitAge ?? 'year';
    this.allStands = !!process.env.REACT_APP_SETTINGS_ALL_STANDS_IN_MAP;
  }

  unitAgeChange = (UnitAge: UnitAge): void => {
    this.unitAge = UnitAge;
    this._localStorage.setValue('settingsUnitAge', UnitAge);
  }
}
