import * as React from 'react';
import Confirm, { ConfirmProps } from '../../uicomponents/Confirm';
import { action, observable } from 'mobx';
import { createStyles } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { default as withReactions, WithReactionsProps } from '../../withReactions';
import { WithStyles } from '@mui/styles';
import { translateUnit } from '../../models/DataNodeUtils';

type StaticConfirmProps = Omit<ConfirmProps, 'variant' | 'defaultOpen' | 'onOpen'>
  & { variant?: ConfirmProps['variant'] };

const styles = () => createStyles({ });

export default class StaticConfirm extends React.Component<WithStyles<typeof styles> & WithReactionsProps> {
  static model: StaticConfirmProps & { open: boolean } = observable({
    message: '',
    onClick: () => { },
    open: false,
  });

  @action
  static open(config: StaticConfirmProps) {
    const model = StaticConfirm.model;
    model.open = true;
    model.message = config.message;
    model.onClick = config.onClick;
    model.variant = config.variant;
    model.icon = config.icon;
    model.itemClassName = config.itemClassName;
    model.buttonProps = config.buttonProps;
    model.div = config.div;
  }

  static close() {
    StaticConfirm.model.open = false;
  }

  render() {
    const model = StaticConfirm.model;
    if (!model.open) return null;
    const { onClick, variant, message, ...other } = model;

    const onClickConfirm = () => {
      onClick();
      StaticConfirm.close();
    };

    const messageTranslated = typeof message === 'string' ? translateUnit(message) : message;
    return (
      <Confirm
        {...other}
        onClick={onClickConfirm}
        variant={variant || 'button'}
        defaultOpen={true}
        message={messageTranslated}
        onClose={StaticConfirm.close}
      />
    );

  }
}

export const StaticConfirmStyled = withStyles(styles)(withReactions(StaticConfirm));
