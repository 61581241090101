import * as React from 'react';
import Add from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { DataNodePropComplex } from '../../models/DataNodeUtils';
import DynamicElementDialog from './DynamicElementDialog';

type Props = {
  className?: string;
  model: DataNodePropComplex;
};

const DynamicElementAddButtonDialog = (props: Props) => {
  const { className, model } = props;
  const [open, setOpen] = React.useState(false);
  const onOpenClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Fab className={className} onClick={onOpenClick}>
        <Add />
      </Fab>
      <DynamicElementDialog open={open} onClose={onClose} model={model} />
    </>
  );
};

export default DynamicElementAddButtonDialog;
