import * as React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { LangKey } from '../../LangKey';
import Project from '../../models/Project';
import StaticConfirm from '../app/StaticConfirm';
import DownloadToolbarMenu from '../download/DownloadToolbarMenu';
import { observer } from 'mobx-react';

type Props = {
  model?: Project;
};

const ProjectStandsMenu: React.FunctionComponent<Props> = observer(({ model }) => {
  if (!model) return null;

  const handleRemoveStands = () => {
    StaticConfirm.open({
      message: LangKey.ProjectRemoveStandConfirm,
      onClick: () => model.setStandToRemove(model.selectedStands),
    });
  };

  const dontHaveSelectedStands = !model.selectedIds.length;

  return (
    <DownloadToolbarMenu
      model={model.layersLoader}
      onSelectAll={model.onSelectAll}
      isAllSelected={model.isAllEstatesSelected}
    >
      <MenuItem
        disabled={dontHaveSelectedStands}
        onClick={dontHaveSelectedStands ? undefined : handleRemoveStands}
      >
        <ListItemIcon>
          <DeleteOutline fontSize={'small'} />
        </ListItemIcon>
        <ListItemText primary={LangKey.ProjectsRemoveStand.t()}/>
      </MenuItem>
    </DownloadToolbarMenu>
  );
});

export default ProjectStandsMenu;
