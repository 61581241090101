import * as React from 'react';
import { Action } from '@simosol/stands-map-editor';

import { appModel } from '../../models/App';
import Project from '../../models/Project';
import { PageEditor } from '../../Browser';
import AppToolbar from '../app/appBar/AppToolbar';
import EditorMap, { NewStandFromEditor } from './EditorMap';
import DataPreloader from '../../uicomponents/DataPreloader';
import Spacer from '../../uicomponents/Spacer';
import Confirm from '../../uicomponents/Confirm';
import ResultSnackBar from '../app/ResultSnackBar';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { DDataNode } from '@simosol/iptim-data-model';
import { Stand, SyncStatus } from '../../models/Stands';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    saveBtn: {
      paddingRight: theme.spacing(2),
    },
  }),
);

type Props = {
  page: PageEditor;
};

const Editor = (props: Props) => {
  if (!appModel.isTapioIsEstates) appModel.browser.page = { p: 'projects' };
  const { page } = props;
  const styles = useStyles();
  const [waitingSave, setWaitingSave] = React.useState<boolean>(false);
  const [actions, setActions] = React.useState<Action[] | undefined>(undefined);

  const project: Project | undefined = appModel.projects.get(page.p1);
  if (!project) {
    ResultSnackBar.show('Project not found', true);
    appModel.browser.page = { p: 'projects' };
    return null;
  }

  const handleOnSaveComplete = (stands: NewStandFromEditor[]) => {
    stands.forEach((stand) => {
      const newStand = new Stand(project, stand as unknown as DDataNode, project.fixStructure(), []);
      newStand.syncStatus = SyncStatus.add;
      project.stands.push(newStand);
      project.addStandData(stand as unknown as DDataNode);
    });
    setWaitingSave(false);
  };

  const selectedStand: string | undefined = page.p3;

  const changeActions = (action: Action[]) => {
    setActions(action);
  };

  return (
    <>
      <AppToolbar
        button={{ type: 'back', page: { p: 'projects' } }}
        title={`Editor ${project.name}`}
      >
        <>
          <Spacer />
          <div className={styles.saveBtn}>
            <Confirm
              variant={'button'}
              onClick={() => setWaitingSave(true)}
              message={'Save changes?'}
              buttonProps={{
                disabled: actions && !actions.includes('save'),
                color: 'primary',
                variant: 'contained',
              }}
              disabled={actions && !actions.includes('save')}
            >
              {'Save'}
            </Confirm>
          </div>
        </>
      </AppToolbar>
      <DataPreloader>
        <EditorMap
          project={project}
          selectedStand={selectedStand}
          waitingSave={waitingSave}
          saveComplete={handleOnSaveComplete}
          changeActions={changeActions}
        />
      </DataPreloader>
    </>
  );
};

export default Editor;
