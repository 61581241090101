import { EditorStand } from '@simosol/stands-map-editor';
import { Stand } from '../Stands';

const useEditorStands = <T extends Stand>(stands?: T[]): EditorStand[] => {
  const getStands = (): EditorStand[] => {
    const mapStands = stands?.map(stand => ({
      id: stand.id,
      extensionLabel: stand.extensionLabel,
      geoJSON: stand.geoJSON!,
      estateId: +stand.project.id,
    }));
    return mapStands ?? [];
  };
  return getStands();
};

export default useEditorStands;
