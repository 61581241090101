export class UtilsFile {
  static saveXML = (xmlText: string, fileName: string) => {
    const blob = new Blob([xmlText], { type: 'text/plain' });
    UtilsFile.fileSave(
      window.URL.createObjectURL(blob),
      `${fileName}.xml`,
    );
  }

  static fileSave = (url: string, name: string) => {
    const el = document.createElement('a');
    el.href = url;
    el.download = name;
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  }
}
