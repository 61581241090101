import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react';
import { KeysOfType } from '@simosol/forms/lib/FormInput';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';

type Props<T> = {
  model: T;
  name: string;
} & (
    { propertyKey: KeysOfType<T, boolean>; checkChecked?: undefined; onChange?: undefined; } |
    { propertyKey?: undefined; checkChecked: (model?: T) => boolean; onChange: (model?: T) => void; }
  ) & Omit<FormControlLabelProps, 'onChange' | 'value' | 'control'>;

export const ReactCheckbox = observer(<T extends unknown>(props: Props<T>) => {

  const { model, onChange, name, checkChecked, propertyKey, ...other } = props;

  const checked: boolean = (typeof propertyKey === 'undefined'
    ? checkChecked?.(model)
    : model[propertyKey as string]) || false;
  const toggleValue = () => {
    if (typeof propertyKey !== 'undefined') {
      model[propertyKey as string] = !checked;
    } else {
      onChange?.(model);
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={toggleValue} name={name} />}
      {...other}
    />
  );
});
