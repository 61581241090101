import { LangKey } from '../../LangKey';
import { translateUnit } from '../../models/DataNodeUtils';

export const getLayersLang = () => {
  return [
    { id: 1, name: translateUnit(LangKey.OfflineBaseLayer) },
    { id: 2, name: translateUnit(LangKey.OfflineOpenStandData) },
    { id: 3, name: translateUnit(LangKey.OfflineEstateBorders) },
  ];
};
