import * as React from 'react';
import {
  Dialog,
  DialogActions, DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
} from '@mui/material';
import { LangKey } from '../../LangKey';
import { observer } from 'mobx-react';
import Button from '../../uicomponents/Button';
import FormCheckbox from '../../uicomponents/forms/FormCheckbox';
import { translateUnit } from '../../models/DataNodeUtils';
import { StatusType } from '../../models/UtilTypes';
import { getLayersLang } from './Layers';
import ResultSnackBar from '../app/ResultSnackBar';

type Props = {
  model: {
    availableLayers: number[],
    selectedIds: (number | string)[],
    downloadDialogOpened: boolean,
    onChangeLayer: (id: number) => void,
    selectedLayers: number[],
    onClickDownloadMap: () => boolean | Promise<boolean>,
    downloadStatus: StatusType,
  } | undefined;
};

const BtnDownload: React.FunctionComponent<Props> = observer(({ model }) => {
  const onClick = async () => {
    if (model?.onClickDownloadMap) {
      const result = await model.onClickDownloadMap();
      if (result) {
        ResultSnackBar.success(translateUnit(LangKey.OfflineDownloadMapHasBeenSaved));
      }
    }
  };

  return (
    <Button
      onClick={onClick}
      autoFocus
      color={'primary'}
      variant={'contained'}
      disabled={!model?.selectedLayers.length}
      loading={model?.downloadStatus === 'pending'}
    >
      {LangKey.OfflineDownload}
    </Button>
  );
});

const DownloadDialog: React.FunctionComponent<Props> = observer((props) => {
  const { model } = props;

  if (!model || !model.downloadDialogOpened) return null;

  const handleClose = () => model.downloadDialogOpened = false;
  const availableLayers = model.availableLayers;
  const layersArray = getLayersLang();

  return (
    <Dialog onClose={handleClose} open={model.downloadDialogOpened}>
      <DialogTitle>{translateUnit(LangKey.OfflineSelectLayersTitle)}</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>{translateUnit(LangKey.OfflineLayers)}</FormLabel>
          <FormGroup>
            {layersArray.map((val, key) => {
              return (
                <FormCheckbox
                  value={val.id}
                  label={val.name}
                  key={key}
                  disabled={!availableLayers.find(v => v === val.id)}
                  model={model}
                  listId={'selectedLayers'}
                  onChange={model!.onChangeLayer}
                />);
            })}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{LangKey.OfflineCancel}</Button>
        <BtnDownload {...props} />
      </DialogActions>
    </Dialog>
  );
});

export default DownloadDialog;
