import * as React from 'react';
import MapGpsMenu from './gps/MapGpsMenu';
import OwnLocation from './ownLocation/OwnLocation';
import { OwnLocationButton } from '@simosol/stands-map';

type Props = {
  onSaveGPSLocation?: () => void;
  onStartTrackRecording?: () => void;
  onStopTrackRecording?: () => void;
} & Parameters<typeof OwnLocationButton>[0];

const OwnLocationManager: React.FunctionComponent<Props> = (
  {
    onSaveGPSLocation,
    onStopTrackRecording,
    onStartTrackRecording,
    ...props
  },
) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const handleMenuClick = onStartTrackRecording ?
    ((event: React.MouseEvent) => setAnchorEl(event.currentTarget)) : undefined;
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <>
      <OwnLocation {...props} openGPSMenu={handleMenuClick} />
      {!!(onSaveGPSLocation && onStopTrackRecording && onStartTrackRecording) && <MapGpsMenu
        anchorEl={anchorEl}
        handleClose={handleMenuClose}
        onSaveGPSLocation={onSaveGPSLocation}
        onStopTrackRecording={onStopTrackRecording}
        onStartTrackRecording={onStartTrackRecording}
      />}
    </>
  );
};

export default OwnLocationManager;
