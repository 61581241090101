import * as React from 'react';
import { Theme } from '@mui/material/styles';
import DynamicElementProp from './DynamicElementProp';
import { default as classNames } from 'classnames';
import UiText from '../../uicomponents/UiText';
import { DataNodeProp as MDynamicElementProp } from '@simosol/iptim-data-model';
import createStyles from '@mui/styles/createStyles';
import { DataNode } from '../../models/DataNodeUtils';
import { HBox } from '../../uicomponents/Boxes';
import { LangKey } from '../../LangKey';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import Confirm from '../../uicomponents/Confirm';
import useQueueEditing from './useQueueEditing';
import makeStyles from '@mui/styles/makeStyles';

const borderColor = 'rgba(0,0,0,0.08)';
const border = '1px solid ' + borderColor;
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
  },
  borderBottom: {
    borderBottom: border,
  },
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingLeft: 12,
    flex: 1,
  },
  borderTopBottom: {
    borderBottom: border,
    borderTop: border,
  },
}));

type Props = {
  dataNode: DataNode;
  model: MDynamicElementProp[];
  onDelete?: () => void;
  className?: string;
  headerLabel?: string;
  displayRequired?: boolean;
  onNull?: () => void;
};

const DynamicElementProps = (props: Props) => {
  const {
    dataNode,
    model,
    onDelete,
    className,
    headerLabel,
    displayRequired,
    onNull,
  } = props;

  const { openEditPopup, onClosePopup } = useQueueEditing(model, onNull);

  const classes = useStyles(props);
  return (
    <div className={classNames(classes.root, className)}>
      <HBox>
        {headerLabel &&
          <UiText className={classes.header} variant={'h6'}>{headerLabel}</UiText>
        }
        {onDelete &&
          <Confirm
            variant={'icon'}
            onClick={onDelete}
            message={LangKey.ConfirmDelete.t({ value: dataNode.descriptor.name })}
          >
            <DeleteOutlineOutlined />
          </Confirm>
        }
      </HBox>
      {model.map((item, index) => (
        <React.Fragment key={item.key}>
          <DynamicElementProp
            key={item.key}
            item={item}
            itemIndex={index}
            onClosePopup={onClosePopup}
            openEdit={openEditPopup[index]}
            displayRequired={displayRequired}
            className={index === 0 ? classes.borderTopBottom : classes.borderBottom}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default DynamicElementProps;
