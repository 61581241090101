import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { StatusType } from '../UtilTypes';
import { appModel } from '../App';
import { MapboxDB } from '../MapboxDB';
import DataLoader from '../DataLoader';
import { Stand } from '../Stands';
import DB from '../DB';

export class LayersLoader {
  @observable
  private _downloadDialogOpened = false;
  @computed
  get downloadDialogOpened() { return this._downloadDialogOpened; }
  set downloadDialogOpened(value) { this._downloadDialogOpened = value; }

  @observable private _downloadStatus: StatusType = 'none';
  @computed get downloadStatus() { return this._downloadStatus; }
  set downloadStatus(value) { this._downloadStatus = value; }

  get selectedIds() {
    return this.getSelectedStands()
      .map(v => v.id);
  }

  @observable
  private _selectedLayers: number[] = [];
  @computed
  get selectedLayers() { return this._selectedLayers; }

  constructor(private getSelectedStands: () => Stand[]) {
    makeObservable(this);
  }

  get availableLayers() {
    const layers: number[] = [];

    const stands = this.getSelectedStands();
    stands.forEach(stand => layers.push(...stand.layersDb));

    return [
      DataLoader.LAYER_ALL_ESTATES,
      DataLoader.LAYER_VECTOR_BASE,
      DataLoader.LAYER_STAND_BORDERS,
    ].filter((layerId) => {
      const layersCount = layers.filter(v => v === layerId).length;
      return layersCount < stands.length;
    });
  }

  onClickDownloadMap = async () => {
    const selectedStands = this.getSelectedStands();
    if (!selectedStands.length || !this.selectedLayers.length) return false;

    this.downloadStatus = 'pending';
    this._downloadDialogOpened = false;

    await Promise.all(this.selectedLayers.map((layerId) => {
      const promise = appModel.api.getTilesCache(selectedStands.map(v => v.id), undefined, layerId);

      promise.then(async (v) => {
        if (v.data) {
          v.data.forEach((url) => {
            fetch(url).then((response) => {
              if (response.ok) {
                response.arrayBuffer().then((result) => { MapboxDB.indexedPut(url, result); });
              }
            });
          });
        }
      });

      return promise;
    }));

    appModel.db.pushMapLayers(selectedStands.map(v => DB.getName(v.id, v.project.id)), this.selectedLayers);
    selectedStands.map((stand) => {
      stand.layersDb = Array.from(new Set([...stand.layersDb, ...this.selectedLayers]));
    });

    runInAction(() => {
      this.downloadStatus = 'fulfilled';
      this._selectedLayers = [];
    });
    return true;
  }

  @action
  onChangeLayer = (id: number) => {
    const idIndex = this.selectedLayers.indexOf(id);
    if (idIndex > -1) {
      this._selectedLayers.splice(idIndex, 1);
    } else if (this.availableLayers.includes(id)) {
      this._selectedLayers.push(id);
    }
  }
}
