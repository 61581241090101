import * as React from 'react';

import { appModel } from '../../models/App';
import Project from '../../models/Project';
import { Stand, SyncStatus } from '../../models/Stands';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import Map from '@mui/icons-material/Map';
import RestoreFromTrash from '@mui/icons-material/RestoreFromTrash';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { LangKey } from '../../LangKey';
import StaticConfirm from '../app/StaticConfirm';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  listItem: {
    minWidth: theme.spacing(4),
  },
}));

type Props = {
  model: Stand;
  project: Project;
};

const StandMenu = (props: Props) => {
  const { model, project } = props;
  const styles = useStyles();

  const disabled = model.syncStatus === SyncStatus.del;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleOpenProject = () => {
    appModel.stands.showStand(model);
  };
  const handleOpenEditor = () => {
    appModel.browser.page = { p: 'editor', p1: project.id, p2: 'stand', p3: model.id };
  };
  const handleRemoveStands = () => {
    StaticConfirm.open({
      message: LangKey.ProjectRemoveStandConfirm,
      onClick: () => project.setStandToRemove([model]),
    });
  };
  const handleRestoreStand = () => {
    model.setStandStatus(SyncStatus.done);
  };
  const handleRemoveStandsFromBase = async () => {
    await project.removeSelectedStands([model]);
  };

  return (
    <div className={styles.root}>
      <IconButton onClick={handleOpenMenu} size="large">
        <MoreVert color="action" />
      </IconButton>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={handleOpenProject} disabled={disabled}>
            <ListItemIcon className={styles.listItem}>
              <EditOutlined fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={LangKey.ProjectsOpenStand.t()}/>
          </MenuItem>
          {appModel.isTapioIsEstates && (
            <MenuItem onClick={handleOpenEditor} disabled={disabled}>
              <ListItemIcon className={styles.listItem}>
                <Map fontSize="small"/>
              </ListItemIcon>
              <ListItemText primary={LangKey.ProjectsOpenEditor.t()}/>
            </MenuItem>
          )}
          {model.syncStatus !== SyncStatus.add && (
            <MenuItem onClick={handleRemoveStands} disabled={disabled}>
              <ListItemIcon>
                <DeleteOutline fontSize={'small'} />
              </ListItemIcon>
              <ListItemText primary={LangKey.ProjectsRemoveStand.t()}/>
            </MenuItem>
          )}
          {model.syncStatus === SyncStatus.add && (
            <MenuItem onClick={handleRemoveStandsFromBase} disabled={disabled}>
              <ListItemIcon>
                <DeleteOutline fontSize={'small'} />
              </ListItemIcon>
              <ListItemText primary={LangKey.ProjectsRemoveStand.t()}/>
            </MenuItem>
          )}
          {model.syncStatus === SyncStatus.del && (
            <MenuItem
              onClick={handleRestoreStand}
            >
              <ListItemIcon>
                <RestoreFromTrash fontSize={'small'} />
              </ListItemIcon>
              <ListItemText primary={'Restore stand'}/>
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};

export default StandMenu;
