import EntityCache from '../EntityCache';
import { DMapElement, MapElement } from './MapElement';
import { appModel } from '../App';

export class MapElements extends EntityCache<MapElement, DMapElement>{
  constructor() {
    super(
      data => new MapElement(data),
      data => data.id,
      model => this.fromData(model.data),
    );
  }

  sync = async () => {
    const elements = await appModel.db.getMapElements();
    this.fromArray(elements);
  }

  createNew = (data: Omit<DMapElement, 'id'>) => {
    const dataNew: DMapElement = {
      ...data,
      id: this.getId(),
    };

    return this.fromData(dataNew);
  }

  private getId = () => {
    return (this.length ?
      (Math.max(...this.map(v => +v.id)) + 1) : 1)
      .toString();
  }
}
