import * as React from 'react';
import DataPreloader from '../../uicomponents/DataPreloader';
import { appModel } from '../../models/App';
import AppToolbar from '../app/appBar/AppToolbar';
import { observer } from 'mobx-react-lite';
import MapOpenButton from '../map/MapOpenButton';
import StandListItem from './StandListItem';
import { Checkbox, Divider, FormGroup, Theme } from '@mui/material';
import ProjectStandsMenu from './ProjectStandsMenu';
import DownloadDialog from '../download/DownloadDialog';
import ListItem from '@mui/material/ListItem';
import { LangKey } from '../../LangKey';
import FormControlLabel from '@mui/material/FormControlLabel';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Fab from '../../uicomponents/Fab';
import Add from '@mui/icons-material/Add';

const useStyles = makeStyles((theme: Theme) => createStyles({
  standGroup: {
    paddingBottom: theme.spacing(9),
  },
  pointer: {
    cursor: 'pointer',
  },
  addIcon: {
    position: 'fixed',
    bottom: theme.spacing(11),
    right: theme.spacing(2),
  },
}));

const Project = observer(() => {
  const styles = useStyles();
  const project = appModel.currentProject;
  const hasGeo = project?.stands.some(stand => stand.geoJSONToMap);
/*  const sortModel: SortButtonModel<'taskId' | 'status' | 'dateAsc' | 'dateDesc'> = observable({
    items: [
      { label: LangKey.TasksSortId, value: 'taskId' },
      { label: LangKey.TasksSortStatus, value: 'status' },
      { label: LangKey.TasksSortDateAsc, value: 'dateAsc' },
      { label: LangKey.TasksSortDateDesc, value: 'dateDesc' },
    ],
    value: 'taskId',
  });*/
  return (
    <>
      <AppToolbar
        title={appModel.currentProject ? appModel.currentProject.name : ''}
        button={{ type: 'back', page: { p: 'projects' } }}
      >
        <ProjectStandsMenu model={project}/>
      </AppToolbar>
        {/*<div className={styles.spacer}/>
        <Button
          className={styles.customizeButton}
          color={'inherit'}
          variant={'outlined'}
          onClick={() => console.log('Choose parameters')}
        >
          Customize view
        </Button>
        <SortButton model={sortModel} />*/}
      <DataPreloader>
        {project &&
          <>
            <ListItem onClick={project.onSelectAll} className={styles.pointer}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={project.isAllEstatesSelected}
                    onChange={project.onSelectAll}
                    color="primary"
                  />
                )}
                onClick={project.onSelectAll}
                label={LangKey.OfflineSelectAll.t()}
              />
            </ListItem>
            <Divider />
            <FormGroup classes={{ root: styles.standGroup }}>
              {project.stands.map(stand => (
                <StandListItem
                  stand={stand}
                  project={project}
                  key={stand.id}
                />
              ))}
            </FormGroup>
            {hasGeo && <MapOpenButton onClick={() => appModel.map.showMap(project.id)} />}
            <Fab
              color={'inherit'}
              size={'large'}
              className={styles.addIcon}
              onClick={() => appModel.browser.page = { p: 'editor', p1: project.id }}
            >
              <Add />
            </Fab>
          </>
        }
      </DataPreloader>
      <DownloadDialog model={project?.layersLoader} />
    </>
  );
});

export default Project;
