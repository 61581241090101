import * as React from 'react';
import { observer } from 'mobx-react';

import { appModel } from '../../models/App';
import { Stand, SyncStatus } from '../../models/Stands';
import Project from '../../models/Project';
import StandMenu from './StandMenu';
import MapBtnLayersInfo from '../download/MapBtnLayersInfo';
import { ReactCheckbox } from '../../uicomponents/ReactCheckbox';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

type StandListItemProps = {
  stand: Stand;
  project: Project;
};

const getAreaText = (stand: Stand) => {
  const area = stand.propArea;
  if (area) {
    return stand.areaWithUnit;
  }
  return '';
};

const StandListItem = observer(({ project, stand }: StandListItemProps) => {
  const styles = useStyles();

  const backgroundColor = stand.syncStatus === SyncStatus.del
    ? '#fccfba'
    : stand.syncStatus === SyncStatus.add
      ? '#bdf9eb'
      : undefined;

  return (
    <>
      <ListItem
        classes={{ root: styles.root }}
        style={{ backgroundColor }}
        disabled={stand.syncStatus === SyncStatus.del}
      >
        <ReactCheckbox
          model={stand}
          onChange={() => project.toggleStand(stand)}
          checkChecked={() => project.selectedIds.includes(stand.id)}
          name={stand.id}
          label={''}
          disabled={stand.syncStatus === SyncStatus.del || stand.syncStatus === SyncStatus.add}
        />
        <ListItemText
          onClick={() => {
            stand.syncStatus !== SyncStatus.del && appModel.stands.showStand(stand);
          }}
          primary={stand.extensionLabel}
          secondary={`${getAreaText(stand)}`}
        />
        <MapBtnLayersInfo model={stand}/>
        <StandMenu model={stand} project={project} />
      </ListItem>
    </>
  );
});

export default StandListItem;
