import * as React from 'react';
import { action, observable } from 'mobx';

import { default as withReactions, WithReactionsProps } from '../../withReactions';
import { appModel } from '../../models/App';
import { LangKey } from '../../LangKey';

import { WithStyles } from '@mui/styles';
import { createStyles, Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';

type ResultSnackBarProps = {} & WithStyles<typeof styles> & WithReactionsProps;

const styles = (theme: Theme) => createStyles({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  success: {
    backgroundColor: theme.palette.success.dark,
  },
});

export default class ResultSnackBar extends React.Component<ResultSnackBarProps> {
  static model = observable({
    open: false,
    text: '',
    error: false,
    success: false,
  });

  static success = (text: string) => ResultSnackBar.show(text, false, true);
  static error = (text: string) => ResultSnackBar.show(text, true, false);
  @action
  static show(text: string, error: boolean = false, success = false) {
    const model = ResultSnackBar.model;
    model.open = true;
    model.text = text.t();
    model.error = error;
    model.success = success;
  }

  static hide() {
    ResultSnackBar.model.open = false;
  }

  componentDidMount() {
    this.props.reactions.reaction(
      () => appModel.criticalError,
      () => ResultSnackBar.show(appModel.criticalError || LangKey.CommonUnknownCriticalError, true),
    );
  }

  render() {
    const model = ResultSnackBar.model;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}

        open={model.open}
        autoHideDuration={10000}
        onClose={ResultSnackBar.hide}
      >
        <SnackbarContent
          className={model.error ? this.props.classes.error :
            model.success ? this.props.classes.success : undefined}
          style={{ flexWrap: 'nowrap' }}
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {model.error && <Error style={{ marginRight: 16 }}/>}
              {ResultSnackBar.model.text}
            </span>}
          action={
            <IconButton
              key="close"
              color="inherit"
              onClick={ResultSnackBar.hide}
            >
              <Close />
            </IconButton>
          }
        />
      </Snackbar>
    );
  }
}

export const ResultSnackBarStyled = withStyles(styles)(withReactions(ResultSnackBar));
