import * as React from 'react';
import * as ReactDOM from 'react-dom';
import red from '@mui/material/colors/red';
import amber from '@mui/material/colors/amber';
import 'typeface-roboto';
import './styles/index.scss';

import { lang } from './Lang';
import AppContainer from './views/app/AppContainer';
import UpdateDialog from './views/app/UpdateDialog';
import { register } from './serviceWorker';

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { createGenerateClassName, StylesProvider } from '@mui/styles';

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    appDrawer: {
      width: number;
    };
    appBar: {
      toolBarHeight: number;
      tabsHeight: number;
    };
  }
  // noinspection JSUnusedGlobalSymbols
  interface ThemeOptions {
    appDrawer: {
      width: number;
    };
    appBar: {
      toolBarHeight: number;
      tabsHeight: number;
    };
  }
}

const classNameGenerator = createGenerateClassName({
  productionPrefix: 'iptim-offline',
});

const root = document.getElementById('root') as HTMLElement;
export const appPalette = {
  50: '#f2f6e6',
  100: '#dee9c1',
  200: '#c8da98',
  300: '#b1cb6f',
  400: '#a1bf50',
  500: '#90b431',
  600: '#88ad2c',
  700: '#7da425',
  800: '#739c1f',
  900: '#1b4243',
  A100: '#e6ffc0',
  A200: '#d3ff8d',
  A400: '#bfff5a',
  A700: '#b5ff41',
};

export const isCordova = navigator.userAgent.match(/Cordova/i);
let initDevice = (onComplete: () => void) => { onComplete(); };
if (isCordova) {
  initDevice = (onComplete: () => void) => {
    document.addEventListener(
      'deviceready',
      () => {
        onComplete();
      },
      false);
  };
} else {
  register();
}

const initDom = () => {
  const theme = createTheme({
    palette: {
      primary: appPalette,
      secondary: amber,
      error: red,
      text: {
        primary: '#000000',
      },
      contrastThreshold: 1.8,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
    appDrawer: {
      width: 240,
    },
    appBar: {
      toolBarHeight: 64,
      tabsHeight: 48,
    },
    typography: {
    },
  });

  ReactDOM.render(
    <StylesProvider generateClassName={classNameGenerator}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <UpdateDialog>
              <AppContainer/>
            </UpdateDialog>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>,
    root as HTMLElement,
  );
};

initDevice(() => lang.init(initDom));
