import * as React from 'react';
import {
  FixedSizeList,
  ListChildComponentProps,
} from 'react-window';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '../../../uicomponents/Button';
import {
  DataNodePropCategory,
  DataNodePropDynamicCategory,
  dataNodePropType,
} from '../../../models/DataNodeUtils';
import { DescriptorType } from '@simosol/iptim-data-model';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import { HBox } from '../../../uicomponents/Boxes';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useMobile } from '../../../uicomponents/Responsive';
import ResultSnackBar from '../../app/ResultSnackBar';
import UiText from '../../../uicomponents/UiText';
import IconButton from '@mui/material/IconButton';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import Save from '@mui/icons-material/Save';
import SkipNext from '@mui/icons-material/SkipNext';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import { observer } from 'mobx-react-lite';
import { LangKey } from '../../../LangKey';

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    flex: 1,
  },
  iconBtn: {
    cursor: 'pointer',
  },
  searchField: {
    maxWidth: theme.spacing(20),
  },
}));

type Item = DataNodePropCategory | DataNodePropDynamicCategory;

type CategoryPropEditorProps = {
  item: Item;
  itemIndex: number;
  open: boolean;
  viewBack: boolean;
  onClose: (itemIndex: number, click: boolean, back?: boolean) => void;
};

const unsetValue = '__unset__';
let valueKey: string = '';

const EditorCategory = observer((props: CategoryPropEditorProps) => {
  const { open, onClose, item, itemIndex, viewBack } = props;
  const styles = useStyles();

  const [filter, setFilter] = React.useState<string>('');

  const isMobile = useMobile();

  const formValue = (item: Item) => item.value !== undefined && item.value !== null
    ? item.value.toString()
    : unsetValue;

  const [value, setValue] = React.useState<string | undefined>(() => formValue(item));

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'Enter' && open) {
      save(false);
      return;
    }
    if (e.key.length === 1) {
      resetValueKey();
      valueKey = valueKey + e.key.toString().toUpperCase();
      setValue(valueKey);
    }
    if (item.displayValue) ResultSnackBar.hide();
  };

  const resetValueKey = () => {
    if (valueKey !== '') return;
    window.setTimeout(
      () => {
        valueKey = '';
      },
      500,
    );
  };

  const cancel = () => {
    setValue(formValue(item));
    onClose(itemIndex, true);
  };

  const clear = () => {
    setValue(undefined);
    item.value = undefined;
    onClose(itemIndex, true);
  };

  const save = (click: boolean) => {
    if (!options.find(op => op.value === value) && !options.find(op => op.value === Number(value))) {
      ResultSnackBar.show('Options not found', true);
      return;
    }
    if (value === unsetValue) {
      item.value = undefined;
    } else {
      if (dataNodePropType(item, DescriptorType.category)) {
        if (item.descriptor.name === 'developmentClass') {
          // TODO: It is necessary to send a string, because the Class is a separate entity.
          // https://simosol.monday.com/boards/876464629/pulses/2933589608
          // @ts-ignore
          item.value = value;
        } else {
          item.value = Number(value);
        }
      } else if (dataNodePropType(item, DescriptorType.dynamicCategory)) {
        item.value = value;
      }
    }
    if (item.descriptor.isRequired) {
      item.displayValue ? onClose(itemIndex, click) : ResultSnackBar.show(LangKey.FormsErrorRequired.t(), true);
    } else onClose(itemIndex, click);
  };

  const options: { value: string | number, label: string }[] =
    React.useMemo(() => item.getOptions(), [item]);

  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };
  const searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const saveClick = () => {
    ResultSnackBar.hide();
    save(true);
  };

  const saveNext = () => {
    ResultSnackBar.hide();
    save(false);
  };

  const backPopup = () => {
    setValue(formValue(item));
    onClose(itemIndex, false, true);
  };

  const filteredOptions = options.filter(option => option.label.match(new RegExp(filter, 'i')));

  const ITEM_HEIGHT = 48;

  const RowLabel = ({ index, data, style }: ListChildComponentProps) => {
    const option = data[index];
    return (
      <FormControlLabel
        value={option.value.toString()}
        key={option.value}
        control={<Radio/>}
        label={option.label}
        style={{ ...style }}
      />
    );
  };

  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={cancel}
      onKeyUp={onKeyPress}
    >
      <DialogTitle>
        <HBox gap={8} align={'center'}>
          <div className={styles.title}>{item.displayName}</div>
          <TextField
            value={filter}
            variant={'outlined'}
            size={'small'}
            autoFocus={false}
            onChange={searchChange}
            InputProps={{
              startAdornment: <Search className={styles.iconBtn} />,
              endAdornment: (
                filter !== '' && <Close className={styles.iconBtn} onClick={() => setFilter('')} />
              ),
            }}
            className={styles.searchField}
          />
        </HBox>
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          value={value}
          onChange={onOptionChange}
        >
          <FixedSizeList
            height={ITEM_HEIGHT * 8}
            width={415}
            itemSize={ITEM_HEIGHT}
            itemCount={filteredOptions.length}
            itemData={filteredOptions}
          >
            {RowLabel}
          </FixedSizeList>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        { !isMobile
          ? <UiText variant={'caption'}>{LangKey.DynamicPropertyInfoSaveCategoryNext.t()}</UiText>
          : null
        }
        {itemIndex > 0 && viewBack
          ? <IconButton onClick={backPopup} size="large"> <NavigateBefore/> </IconButton>
          : null
        }
        <IconButton onClick={clear} size="large"> <DeleteOutline/> </IconButton>
        { !isMobile
          ? <Button onClick={cancel} color={'inherit'} variant={'contained'}>
              {LangKey.CommonCancel.t()}
            </Button>
          : null
        }
        <Button onClick={saveClick} variant={'contained'} color={'primary'}>
          {isMobile ? <Save/> : LangKey.CommonSave.t()}
        </Button>
        { isMobile
          ? <Button onClick={saveNext} variant={'contained'} color={'primary'}>{<Save/>}{<SkipNext/>}</Button>
          : null
        }
      </DialogActions>
    </Dialog>
  );
});

export default EditorCategory;
