import { DataNodeProp as MDynamicElementProp } from '@simosol/iptim-data-model/lib/DataNode';
import * as React from 'react';
import { DescriptorType } from '@simosol/iptim-data-model';
import { dataNodePropType } from '../../models/DataNodeUtils';

const useQueueEditing = (model: MDynamicElementProp[], isNull?: () => void) => {
  const [openEditPopup, setOpenEditPopup] = React.useState<boolean[]>([]);

  React.useEffect(
    () => {
      if (model.length > 0) {
        setOpenEditPopup((Array(model.length)).fill(false));
      }
    },
    [model],
  );

  const changeArrEditPopups = (itemIndex: number | null, click: boolean) => {
    setOpenEditPopup([]);
    const newArr: boolean[] = openEditPopup;
    newArr.map((_elem, index) => {
      if (itemIndex !== null && index === itemIndex) setOpenEditPopup(prevArray => [...prevArray, true]);
      else setOpenEditPopup(prevArray => [...prevArray, false]);
    });
    if (itemIndex === null && !click && isNull) isNull();
  };

  const onClosePopup = (itemIndex: number, click: boolean, back?: boolean) => {
    if (!click) {
      changeArrEditPopups(
        back
          ? onSearchItemNumericPrev(model, itemIndex)
          : onSearchItemNumeric(model, itemIndex),
        click,
      );
    } else changeArrEditPopups(null, click);
  };

  const onSearchItemNumeric = (model: MDynamicElementProp[], minIndex: number): number | null => {
    let result: number | null = null;
    model.map((item, index) => {
      if (
        (
          dataNodePropType(item, DescriptorType.numeric) || dataNodePropType(item, DescriptorType.category)
        )
        && item.descriptor.isEditable
        && index > minIndex
        && result === null
      ) {
        result = index;
      }
    });
    return result;
  };
  const onSearchItemNumericPrev = (model: MDynamicElementProp[], minIndex: number): number | null => {
    let result: number | null = null;
    let prevIndex: number | null = null;
    model.map((item, index) => {
      if (
        prevIndex !== null
        && (
          dataNodePropType(item, DescriptorType.numeric)
          || dataNodePropType(item, DescriptorType.category)
        )
        && item.descriptor.isEditable
        && index === minIndex
      ) {
        result = prevIndex;
      }
      if (
        (
          dataNodePropType(item, DescriptorType.numeric)
          || dataNodePropType(item, DescriptorType.category)
        )
        && item.descriptor.isEditable
        && result === null
      ) prevIndex = index;
    });
    return result;
  };

  return { onClosePopup, openEditPopup, setOpenEditPopup };
};

export default useQueueEditing;
