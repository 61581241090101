import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { appModel } from '../../models/App';
import DataPreloader from '../../uicomponents/DataPreloader';
import { propsDisplayFiltered } from '../../models/DataNodeUtils';
import DynamicElementProps from '../stands/DynamicElementProps';
import { observer } from 'mobx-react-lite';
import AppToolbar from '../app/appBar/AppToolbar';
import MapOpenButton from '../map/MapOpenButton';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    padding: theme.spacing(4, 1.5),
  },
}));

const FreeGeometry = observer(() => {
  const fg = appModel.freeGeometries.currentFG;
  const styles = useStyles();

  return (
    <>
      <AppToolbar
        title={fg ? fg.name : ''}
        button={{ type: 'back', page: { p: 'freeGeometries' } }}
      />
      <DataPreloader>
        {fg && (
          <>
            <div className={styles.container}>
              <DynamicElementProps model={propsDisplayFiltered(fg.props)} dataNode={fg.dataNode} />
            </div>
            <MapOpenButton onClick={() => appModel.browser.page = { p: 'map', p1: 'freeGeometry', p2: `${fg.id}` }}/>
          </>
        )}
      </DataPreloader>
    </>
  );
});

export default FreeGeometry;
