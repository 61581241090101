import * as React from 'react';

import { appModel } from '../../models/App';
import AppToolbar from '../app/appBar/AppToolbar';
import { observer } from 'mobx-react';
import DataPreloader from '../../uicomponents/DataPreloader';
import DynamicElementProps from './DynamicElementProps';
import ListItemText from '../../uicomponents/ListItemText';
import UiText from '../../uicomponents/UiText';
import MapOpenButton from '../map/MapOpenButton';
import { action } from 'mobx';
import { DataNode, DataNodePropComplex, propsDisplayFiltered } from '../../models/DataNodeUtils';

import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import { ListItem, ListItemSecondaryAction } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DynamicElementAddButtonDialog from './DynamicElementAddButtonDialog';
import { DescriptorType } from '@simosol/iptim-data-model';
import LinearProgress from '@mui/material/LinearProgress';
import DescriptorName from '../../models/DescriptorName';
import { LangKey } from '../../LangKey';
import Confirm from '../../uicomponents/Confirm';
import { DeleteOutlineOutlined } from '@mui/icons-material';

const styles = (theme: Theme) => createStyles({
  root: {},
  propsTableInList: {
    marginBottom: theme.spacing(3),
  },
  buttonCont: {
    position: 'fixed',
    bottom: theme.spacing(7),
    right: theme.spacing(2),
  },
  addNodeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    bottom: theme.spacing(4),
  },
  progress: {
    position: 'absolute',
    bottom: theme.spacing(0),
    left: theme.spacing(0),
    width: '100%',
  },
  secondaryAction: {
    right: 0,
  },
  listCont: {
    overflow: 'auto',
    height: '100%',
    listStyleType: 'none',
  },
});

type Props = {} & WithStyles<typeof styles>;

@observer
class DynamicElement extends React.Component<Props> {
  private _onBackClick = () => {
    appModel.stands.previousStandPage();
  }

  private _onElementClick = (element: DataNode) => () => {
    appModel.stands.onElementClick(element);
  }

  private _getTitle = (dynamicElement: DataNode): string => {
    let title: string = '';
    const disName = dynamicElement.descriptor.displayName;
    if (disName !== 'Stand' && disName !== 'Puustokuvio') {
      title += `${disName}:`;
    }
    title += `${dynamicElement.listPrimaryPropDisplayValue}` || '';
    if (disName === 'Stand' || disName === 'Puustokuvio') {
      const extention = dynamicElement.getValue('extension', DescriptorType.text);
      if (extention) title += `.${extention}`;
    }
    let parentTitle: string = '';
    if (dynamicElement.parent) {
      parentTitle = this._getTitle(dynamicElement.parent);
    }
    if (parentTitle) title = parentTitle + ' / ' + title;

    return title;
  }

  @action
  private _onMapOpen = () => {
    const stand = appModel.stands.currentStand;
    if (!stand) return;
    // appModel.stands.back();
    const pg = appModel.browser.page;
    if (pg.p !== 'map') {
      appModel.map.showMap(stand.project.id, stand.id);
    }
  }

  @action
  private _onDeleteClick = (element: DataNode, model: DataNodePropComplex) => () => {
    model.deleteElement(element.id);
  }

  render() {
    const { classes } = this.props;
    const standPage = appModel.stands;
    const pageData = standPage.currentPageData;
    if (!pageData) return null;
    const stand = standPage.currentStand;
    const tabs = standPage.tabs;
    let title = this._getTitle(pageData.element);
    const estateName = stand?.project.name;
    if (estateName) title = `${estateName} • ${title}`;

    const isOperation = pageData.type === 'prop' && pageData.prop.key === DescriptorName.operation;
    const isTreeStand = pageData.type === 'prop' && pageData.prop.key === DescriptorName.treeStand;
    const isTreatments = pageData.type === 'prop' && pageData.prop.key === DescriptorName.treatments;
    const showDelete = isOperation || isTreeStand;
    const hideAddInTreeStand = isTreeStand
      && pageData.prop.nodes.some(n => n.getValue('type', DescriptorType.category) === 1);
    const hideAddInTreatments = isTreatments
      && pageData.prop.nodes.some(n => n.getValue('treatmentType', DescriptorType.category) === 5);
    const hideAdd = hideAddInTreeStand || hideAddInTreatments;

    return (
      <>
        <AppToolbar
          title={title}
          button={{ type: 'back', onClick: this._onBackClick }}
          tabs={tabs.data.length > 0 ? tabs : undefined}
        />
        <DataPreloader>
          {pageData &&
          <>
            {standPage.loading && (
              <LinearProgress
                variant={'indeterminate'}
                classes={{
                  root: classes.progress,
                }}
              />
            )}
            {pageData.type === 'info' &&
              <DynamicElementProps model={propsDisplayFiltered(pageData.element.props)} dataNode={pageData.element} />
            }
            {pageData.type === 'prop' && (
              <div className={classes.listCont}>
                {pageData.prop.nodes.map((element, index) =>
                  element.listPrimaryPropDisplayValue
                    ? <ListItem button key={index} onClick={this._onElementClick(element)}>
                        <ListItemText
                          primary={element.listPrimaryPropDisplayValue}
                        />
                        {element.listSecondaryPropDisplayValue &&
                          <UiText>{element.listSecondaryPropDisplayValue}</UiText>
                        }
                        {showDelete && (
                          <ListItemSecondaryAction classes={{ root: classes.secondaryAction }}>
                            <Confirm
                              variant={'icon'}
                              onClick={this._onDeleteClick(element, pageData.prop)}
                              message={LangKey.ConfirmDelete.t({ value: `${element.listPrimaryPropDisplayValue}` })}
                              actionsYes={{ color: 'error', text: LangKey.CommonDelete.t() }}
                            >
                              <DeleteOutlineOutlined />
                            </Confirm>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    : (
                      <DynamicElementProps
                        className={classes.propsTableInList}
                        key={index}
                        model={propsDisplayFiltered(element.props)}
                        dataNode={element}
                        headerLabel={'#' + (index + 1).toString()}
                        onDelete={this._onDeleteClick(element, pageData.prop)}
                      />
                    ),
                )}
              </div>
            )}
            {stand?.geoJSONToMap && <MapOpenButton onClick={this._onMapOpen} />}
            {pageData.type === 'prop' && !hideAdd &&
              <div className={classes.buttonCont}>
                <DynamicElementAddButtonDialog model={pageData.prop} className={classes.addNodeButton}/>
              </div>
            }
          </>
          }
        </DataPreloader>
      </>
    );
  }
}

export default withStyles(styles)(DynamicElement);
