import * as React from 'react';
import Spacer from '../../uicomponents/Spacer';
import { IconButton, Menu, Theme } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import makeStyles from '@mui/styles/makeStyles';
import { createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    color: theme.palette.primary[50],
  },
}));

type Props = {
  setAnchorEl: (el: Element | null) => void;
  anchorEl: Element | null;
};

const AppToolbarMenu: React.FunctionComponent<Props> = ({ anchorEl, setAnchorEl, children }) => {

  const styles = useStyles();

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Spacer />
      <IconButton onClick={handleClick}>
        <MoreVert className={styles.button} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default AppToolbarMenu;
