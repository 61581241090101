import * as React from 'react';
import AppToolbar from '../app/appBar/AppToolbar';
import { LangKey } from '../../LangKey';
import DataPreloader from '../../uicomponents/DataPreloader';
import AppMap from '../map/AppMap';
import { appModel } from '../../models/App';

type Props = {};

const MapElementsMap: React.FunctionComponent<Props> = (props) => {

  return (
    <>
      <AppToolbar title={LangKey.MapElementsTitle} button={{ type: 'menu' }} />
      <DataPreloader>
        <AppMap mapElements={appModel.mapElements.map(v => v)} gpsRecording />
      </DataPreloader>
    </>
  );
};

export default MapElementsMap;
