import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { AppBarContent } from './AppBar';
import Hidden from '@mui/material/Hidden';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UiText from '../../../uicomponents/UiText';
import IconButton from '../../../uicomponents/IconButton';
import { Page } from '../../../Browser';
import { AppDrawerMenuButton } from '../AppDrawer';
import { observer } from 'mobx-react';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles, withTheme, WithTheme } from '@mui/styles';
import { appModel } from '../../../models/App';
import Warning from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import { LangKey } from '../../../LangKey';
import SaveAs from '@mui/icons-material/SaveAs';
import Downloading from '@mui/icons-material/Downloading';
import Done from '@mui/icons-material/Done';

const styles = (theme: Theme) => createStyles({
  root: {
    paddingLeft: theme.spacing(3),
    minHeight: theme.appBar.toolBarHeight,
    paddingRight: theme.spacing(1),
  },
  rootWithMenu: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
    },
  },
  rootWithBack: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(0.5),
  },
  tabs: {
    width: '100%',
  },
  tab: {
    flexShrink: 0,
    flexGrow: 1,
    maxWidth: 'none',
  },
  title: {
    lineHeight: 1.2,
    marginRight: theme.spacing(2),
  },
});

export interface AppToolbarTabs {
  data: { label: string, value: string | number }[];
  value: string | number;
  onClick: (value: string | number) => void;
}

type AppToolbarProps = {
  title?: string | string[];
  button?: { type: 'menu' } | { type: 'back', onClick?: () => void, page?: Page };
  tabs?: AppToolbarTabs;
  children?: JSX.Element;
};

type AppToolbarPropsWithStyles = AppToolbarProps & WithStyles<typeof styles> & WithTheme<Theme>;

@observer
class AppToolbarBase extends React.Component<AppToolbarPropsWithStyles> {
  private _onTabClick = (value: string | number) => () => {
    if (!this.props.tabs) return;
    this.props.tabs.onClick(value);
  }

  componentDidMount() {
    const { theme, tabs } = this.props;
    appModel.toolBarHeight = theme.appBar.toolBarHeight + (tabs ? theme.appBar.tabsHeight : 0);
  }
  componentDidUpdate() {
    this.componentDidMount();
  }

  componentWillUnmount() {
    appModel.toolBarHeight = undefined;
  }

  render() {
    const { classes, button, title } = this.props;
    let rootClasses = '';
    if (button && button.type === 'menu') {
      rootClasses += ' ' + classes.rootWithMenu;
    } else if (button && button.type === 'back') {
      rootClasses += ' ' + classes.rootWithBack;
    }

    return (
      <AppBarContent>
        <Toolbar disableGutters className={rootClasses}>
          {button &&
            <>
              {button.type === 'menu' &&
                <Hidden lgUp>
                  <AppDrawerMenuButton className={classes.button}/>
                </Hidden>
              }
              {button.type === 'back' &&
                <IconButton
                  color={'inherit'}
                  className={classes.button}
                  onClick={button.onClick}
                  page={button.page}
                  size="large"
                >
                  <ArrowBack/>
                </IconButton>
              }
            </>
          }
          <UiText variant={'h6'} color={'inherit'} className={classes.title}>{title}</UiText>
          <div style={{ flex: 1 }}/>
          {this.props.children}
          {(appModel.unsavedData
            || appModel.freeGeometries.getUnsavedFGs().length > 0
            || appModel.projects.unsavedStands.length > 0
          ) ? (
            <Tooltip title={LangKey.CommonUnknownSynchronizing.t()} arrow placement={'right'}>
              <Warning color={'error'} />
            </Tooltip>
          ) : (
            <Tooltip title={LangKey.CommonUnknownSynchronized.t()} arrow placement={'right'}>
              <Done color={'success'} />
            </Tooltip>
          )}
          {appModel.syncIntoDB && (
            <Tooltip title={LangKey.CommonUnknownSyncIntoDB.t()} arrow placement={'right'}>
              <SaveAs color={'primary'}/>
            </Tooltip>
          )}
          {appModel.projects.layersLoader.downloadStatus === 'pending' && (
            <Tooltip title={LangKey.CommonUnknownMapDownloading.t()} arrow placement={'right'}>
              <Downloading color={'primary'}/>
            </Tooltip>
          )}
        </Toolbar>
        {this.props.tabs &&
          <Tabs
            variant={'scrollable'}
            className={classes.tabs}
            scrollButtons={false}
            value={this.props.tabs.value}
            indicatorColor={'secondary'}
            textColor={'inherit'}
          >
            {this.props.tabs.data.map(tab =>
              <Tab
                className={classes.tab}
                onClick={this._onTabClick(tab.value)}
                key={tab.value}
                value={tab.value}
                label={tab.label.t()}
              />)}
          </Tabs>
        }
      </AppBarContent>
    );
  }
}

export default withStyles(styles)(withTheme<Theme, typeof AppToolbarBase>(AppToolbarBase));
