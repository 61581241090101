import * as defaultRules from '@simosol/forms/lib/rules';
import { LangKey } from '../../LangKey';
export const required = () => defaultRules.required(LangKey.FormsErrorRequired.t());
export const number = () => defaultRules.number(LangKey.FormsErrorNumber.t());
export const min = (valueMin: number) => (value: any) => {
  if (!value) return undefined;
  const v = typeof value !== 'number' ? value.replace(/,/g , '.') : value;
  const valueIsNumber = defaultRules.number()(v) === undefined;
  if (valueIsNumber && Number(v) < valueMin) return LangKey.FormsErrorMin.t({ value: valueMin });
  return undefined;
};

export const max = (valueMax: number) => (value: any) => {
  if (!value) return undefined;
  const v = typeof value !== 'number' ? value.replace(/,/g , '.') : value;
  const valueIsNumber = defaultRules.number()(v) === undefined;
  if (valueIsNumber && Number(v) > valueMax) return LangKey.FormsErrorMax.t({ value: valueMax });
  return undefined;
};
