import * as React from 'react';
import { Map as BMap } from 'mapbox-gl';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MapButton } from '@simosol/stands-map';
// @ts-ignore
import { ReactComponent as Pointer } from './icon-pointer.svg';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: 24,
  },
  compass: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    borderRadius: '50%',
    overflow: 'visible',
    opacity: 1,
    visibility: 'visible',
    transition: '.1s all ease-in-out',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(0.5),
      left: theme.spacing(0.5),
    },
  },
}));

type Props = {
  map?: BMap;
};

const Compass = (props: Props) => {
  const { map } = props;
  const styles = useStyles();
  const pointer = React.useRef<SVGSVGElement | null>(null);

  React.useEffect(
    () => {
      if (!map) return;
      const onRotate = () => {
        const angle = map.getBearing() * (-1);
        pointer.current?.setAttribute('transform', `rotate(${angle})`);
      };
      map.on('rotate', onRotate);
      return () => {
        map.off('rotate', onRotate);
      };
    },
    [map],
  );

  const getNorth = () => {
    map?.easeTo({ bearing: 0, pitch: 0 });
  };

  return (
    <MapButton className={styles.compass}>
      <Tooltip
        title={'MapButtonsCompass'}
        placement={'right'}
      >
        <Paper className={styles.root} elevation={3}>
          <IconButton onClick={getNorth} size="large">
            <Pointer ref={pointer} width={24} height={24} />
          </IconButton>
        </Paper>
      </Tooltip>
    </MapButton>
  );
};

export default Compass;
