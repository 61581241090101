import { Feature, LineString, MultiPolygon, Point, Polygon } from 'geojson';
import { GeoJSONGeometry, parse as wktParse, stringify as makeWkt } from 'wellknown';
import proj4 from 'proj4';
import { UtilsFile } from './UtilsFile';
import { MapElement } from '../models/map/MapElement';

export type KITFeatures = Point | LineString | Polygon | MultiPolygon;
export type KITFeaturesStrings = 'Point' | 'LineString' | 'Polygon' | 'MultiPolygon';

export class UtilsMap {
  static inverse3067 = (geometry: KITFeatures): KITFeatures => {
    switch (geometry.type) {
      case 'Point': {
        geometry.coordinates = proj4('EPSG:3067').inverse(geometry.coordinates);
        break;
      }
      case 'LineString': {
        geometry.coordinates = geometry.coordinates.map(positionArr => proj4('EPSG:3067').inverse(positionArr));
        break;
      }
      case 'Polygon': {
        geometry.coordinates = geometry.coordinates.map(positionArr =>
          positionArr.map(position => proj4('EPSG:3067').inverse(position)));
        break;
      }
      case 'MultiPolygon': {
        geometry.coordinates = geometry.coordinates.map(positionArr =>
          positionArr.map(position =>  position.map(pos => proj4('EPSG:3067').inverse(pos))));
        break;
      }
    }
    return geometry;
  }

  static forward3067 = (geometry: KITFeatures): KITFeatures => {
    switch (geometry.type) {
      case 'Point': {
        geometry.coordinates = proj4('EPSG:3067').forward(geometry.coordinates);
        break;
      }
      case 'LineString': {
        geometry.coordinates = geometry.coordinates.map(position => proj4('EPSG:3067').forward(position));
        break;
      }
      case 'Polygon': {
        geometry.coordinates = geometry.coordinates.map(positionArr =>
          positionArr.map(position => proj4('EPSG:3067').forward(position)));
        break;
      }
      case 'MultiPolygon': {
        geometry.coordinates = geometry.coordinates.map(positionArr =>
          positionArr.map(position => position.map(pos => proj4('EPSG:3067').forward(pos))));
        break;
      }
    }
    return geometry;
  }

  static geomToGeoJSON = (geom: { wkt: string }): Feature<KITFeatures> => {
    return {
      type: 'Feature',
      geometry: UtilsMap.inverse3067(wktParse(geom.wkt) as KITFeatures),
      properties: {},
    };
  }

  static geoJSONToGeom = (geometry: KITFeatures): string => {
    return makeWkt(UtilsMap.forward3067(geometry) as GeoJSONGeometry);
  }

  static createXMLFeatureFromModel = (model: MapElement) => {
    const CODE = '10031';
    const INFO = 'GPS ROAD';

    const type = model.type;
    const gmlPropertyTagName = type === 'LineString' ? 'lineStringProperty' : 'pointProperty';

    return `<SpecialFeature>
      <sf:FeatureCode>${CODE}</sf:FeatureCode>
      <sf:FeatureInfo>${INFO}</sf:FeatureInfo>
      <gml:${gmlPropertyTagName}>
        <gml:${type} srsName="EUREF-FIN">
          <gml:coordinates>${model.coordinatesString}</gml:coordinates>
        </gml:${type}>
      </gml:${gmlPropertyTagName}>
    </SpecialFeature>`;
  }

  static exportXMLFeatures = (xmlFeatures: string[]) => {
    const xmlString = `<?xml version="1.0" encoding="iso-8859-1"?>
<SpecialFeatureData xmlns="http://standardit.tapio.fi/schemas/specialFeatureData/2010/08/31" xmlns:sf="http://standardit.tapio.fi/schemas/forestData/specialFeature/2010/08/31" xmlns:bd="http://standardit.tapio.fi/schemas/forestData/common/basicDataTypes/2010/08/31" xmlns:gdt="http://standardit.tapio.fi/schemas/forestData/common/geometricDataTypes" xmlns:co="http://standardit.tapio.fi/schemas/forestData/common" xmlns:gml="http://www.opengis.net/gml" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://standardit.tapio.fi/schemas/specialFeatureData/2010/08/31 file:SpecialFeatureData_20100831.xsd">
  <SpecialFeatures>
    ${xmlFeatures.join('\n')}
  </SpecialFeatures>
</SpecialFeatureData>`;

    UtilsFile.saveXML(xmlString, 'featuresData');
  }
}
