import PositionWatcher from '@simosol/stands-map/lib/gps/PositionWatcher';
import { action, computed, makeObservable, observable } from 'mobx';

export class GPSTracking {
  static readonly TRACKING_METER_MIN = 5;
  static readonly TRACKING_DELAY_MS = 5000;

  constructor() {
    makeObservable(this);
  }

  private _positionWatcher: PositionWatcher | undefined;

  @observable
  private _recordingStarted = false;
  @computed
  get recordingStarted() { return this._recordingStarted; }

  @observable
  private _recordingPaused = false;
  @computed
  get recordingPaused() { return this._recordingPaused; }

  // points in track recording
  private _linePoints: { lng: number, lat: number }[] = [];
  lineId = 0;

  private _allLines: { lng: number, lat: number }[][] = [];
  get allLines() {
    return this._allLines.map(line => line.map(coords => ([coords.lng, coords.lat])));
  }

  private _isTracking = false;
  private _isLocation = false;

  // private readonly _trackingDebug = true;
  // private _debugPosPlus = 0;

  onCreateCallback?: (point: { lng: number, lat: number }) => void;

  // redraw line after adding new points
  private _trackTickCallback?: (points: number[][]) => void;

  get myPosition(): GeolocationCoordinates | undefined {
    return this._positionWatcher?.position?.coords;
  }

  get isLocation(): boolean {
    return this._isLocation;
  }
  get isTracking(): boolean {
    return this._isTracking;
  }

  private get linePointsArray() {
    return this._linePoints.map(v => ([v.lng, v.lat]));
  }

  createLocationPoint = (positionWatcher: PositionWatcher) => {
    this._create(true, positionWatcher);
  }

  @action
  createTracking = (positionWatcher: PositionWatcher, trackTickCallback: (points: number[][]) => void) => {
    this._create(false, positionWatcher);
    this._linePoints = [];
    this._trackTickCallback = trackTickCallback;
    this._recordingPaused = false;
    this._recordingStarted = true;

    this._trackMyLocation();
  }

  private _create = (isLocation: boolean, positionWatcher: PositionWatcher) => {
    this._linePoints = [];
    this._isLocation = isLocation;
    this._isTracking = !isLocation;
    this._positionWatcher = positionWatcher;
  }

  @action
  toggleTrackingPause = () => {
    const paused = !this._recordingPaused;
    this._recordingPaused = paused;
    if (!paused) {
      // this._debugPosPlus = 0.00012;
      this._cacheLine();
      this._trackMyLocation();
    } else {
      this._clearTrackingInterval();
    }
  }

  /**
   * save last line and create new
   */
  private _cacheLine = () => {
    this._allLines.push(this._linePoints);
    this._linePoints = [];
    this.lineId = this._allLines.length;
  }

  @action
  stopTracking = () => {
    this._clearTrackingInterval();
    this._trackMyLocation(true);
    this._cacheLine();
    this._recordingStarted = false;
    this._recordingPaused = false;
  }

  private _locationTrackInterval?: NodeJS.Timeout;

  private _trackMyLocation = (forceAdd = false) => {
    if (!this._recordingStarted || this._recordingPaused) return;

    const myPos = {
      lat: this.myPosition?.latitude || 0,
      lng: this.myPosition?.longitude || 0,
    };

    const lastPos = this._linePoints.length ? this._linePoints[this._linePoints.length - 1] : undefined;

    // TODO: FOR TEST ONLY, AUTO MOTION
    /*if (myPosition && lastPosition && this._trackingDebug) {
      const degAdd = 0.00007 + this._debugPosPlus;
      this._debugPosPlus = 0;
      myPosition.lat = lastPosition.lat;
      myPosition.lng = lastPosition.lng;

      if (Math.floor(Math.random() * 10) % 2 === 0) {
        myPosition.lat += degAdd;
      } else {
        myPosition.lng += degAdd;
      }

      console.log(GPSTracking.measure(myPosition.lat, myPosition.lng, lastPosition.lat, lastPosition.lng));
    }*/

    if (
      myPos.lng
      && myPos.lng
      && (
        forceAdd
        || !lastPos
        || GPSTracking.measure(myPos.lat, myPos.lng, lastPos.lat, lastPos.lng) >= GPSTracking.TRACKING_METER_MIN
      )
    ) {
      this._linePoints.push({
        lng: myPos.lng,
        lat: myPos.lat,
      });

      this._trackTickCallback?.(this.linePointsArray);
    }

    this._locationTrackInterval = setTimeout(() => this._trackMyLocation(), GPSTracking.TRACKING_DELAY_MS);
  }

  private _clearTrackingInterval = () => {
    if (this._locationTrackInterval) {
      clearInterval(this._locationTrackInterval);
      this._locationTrackInterval = undefined;
    }
  }

  @action clear = () => {
    this._isTracking = false;
    this._isLocation = false;
    this._positionWatcher = undefined;
    this.onCreateCallback = undefined;

    this._recordingPaused = false;
    this._recordingStarted = false;
    this._linePoints = [];
    this._clearTrackingInterval();
  }

  /**
   * Diff gps position in meters
   */
  private static measure = (lat1: number, lng1: number, lat2: number, lng2: number) => {
    const R = 6378.137; // Radius of earth in KM
    const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    const dLon = lng2 * Math.PI / 180 - lng1 * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180)
      * Math.cos(lat2 * Math.PI / 180)
      * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 1000; // meters
  }
}
