import * as React from 'react';
import uuid from 'uuid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DynamicElementProps from './DynamicElementProps';
import {
  checkRequired,
  createIptimDataNode,
  DataNode,
  DataNodeProp,
  DataNodePropComplex,
  getLocalizedDisplayName,
  propsDisplayFiltered,
} from '../../models/DataNodeUtils';
import ResultSnackBar from '../app/ResultSnackBar';
import Button from '../../uicomponents/Button';
import { LangKey } from '../../LangKey';
import { appModel } from '../../models/App';
import DescriptorName from '../../models/DescriptorName';
import UiText from '../../uicomponents/UiText';

type Props = {
  open: boolean;
  onClose: () => void;
  model: DataNodePropComplex;
};

const DynamicElementDialog = (props: Props) => {
  const { open, onClose, model } = props;

  const createDataNode = () => {
    const uid = uuid.v1();
    const treeStandDefault = model.descriptor.name === DescriptorName.treeStand
      ? { type: 1 }
      : model.descriptor.name === DescriptorName.treatments
        ? { treatmentType: 5 }
        : {};
    return createIptimDataNode({ ...treeStandDefault, uid }, model.descriptor);
  };
  const [dataNode, setDataNode] = React.useState<DataNode | undefined>(() => open ? createDataNode() : undefined);
  const [quickWriteCompleted, setQuickWriteCompleted] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      if (open) {
        setDataNode(createDataNode());
        setQuickWriteCompleted(false);
      }
    },
    [open],
  );

  const onCreateClick = async () => {
    if (!dataNode) return;

    if (!checkRequired(dataNode)) {
      ResultSnackBar.show(LangKey.FormsErrorReqFields.t(), true);
      return;
    }

    // remove any changed information from data
    dataNode.commit();
    dataNode.data.___changeProps = undefined;
    model.addElement(dataNode.data);
    if (appModel.isOffline) {
      appModel.instanceControl.setNewInstance(dataNode.data.uid);
    }
    onClose();
  };

  const hideNoEditable = (props: DataNodeProp[]) => {
    return props.filter((prop) => {
      return !!prop.descriptor.isEditable;
    });
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && quickWriteCompleted && open) {
      setQuickWriteCompleted(false);
      onCreateClick();
    }
  };

  const onNull = () => {
    setQuickWriteCompleted(true);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={open}
      onClose={onClose}
      onKeyUp={onKeyPress}
    >
      <DialogTitle>
        {getLocalizedDisplayName(model.descriptor)}
      </DialogTitle>
      {dataNode && (
        <DialogContent>
          <DynamicElementProps
            dataNode={dataNode}
            model={propsDisplayFiltered(hideNoEditable(dataNode.props))}
            displayRequired
            onNull={onNull}
          />
        </DialogContent>
      )}
      <DialogActions>
        {quickWriteCompleted
          ? <UiText variant={'caption'}>{LangKey.DynamicPropertyInfoCreateAndClose.t()}</UiText>
          : null
        }
        <Button color={'inherit'} variant={'contained'} onClick={onClose}>{LangKey.CommonCancel}</Button>
        <Button color={'primary'} variant={'contained'} onClick={onCreateClick}>{LangKey.CommonOk}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicElementDialog;
