import * as React from 'react';
import { observer } from 'mobx-react';
import { appModel } from '../../models/App';
import { Stand as MapStand } from '@simosol/stands-map';
import useMapStands from './useMapStands';
import ProjectStandsMap from './ProjectStandsMap';
import Project from '../../models/Project';
import { Stand } from '../../models/Stands';
import { FreeGeometry } from '../../models/FreeGeometries';
import { MapElement } from '../../models/map/MapElement';

type Props = {
  gpsRecording?: boolean;
  mapElements?: MapElement[];
};

const StandMapBase: React.FunctionComponent<Props> = observer(({ gpsRecording, mapElements }) => {
  const currentProject: Project | undefined = appModel.map.currentProject;

  const mapStands: MapStand[] = useMapStands(currentProject);

  const currentStand: Stand | undefined  = appModel.map.currentStand;
  const mapGeometry: FreeGeometry | undefined  = appModel.map.currentGeometries;

  const setSelectedStands = (stand?: MapStand) => {
    if (currentProject && stand) {
      const currentStand = appModel.projects.getStand(stand.id);
      if (currentStand) {
        appModel.browser.page = { p: 'map', p1: 'project', p2: currentStand.project.id, p3: currentStand.id };
      }
    }
  };

  return (
    <ProjectStandsMap
      mapStands={mapStands}
      currentProject={currentProject}
      currentStand={currentStand}
      onChange={setSelectedStands}
      mapGeometry={mapGeometry}
      gpsRecording={gpsRecording}
      mapElements={mapElements}
    />
  );
});

export default StandMapBase;
