import * as React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import { LangKey } from '../../../LangKey';
import { appModel } from '../../../models/App';

type Props = {
  anchorEl: Element | null;
  handleClose: () => void;
  onSaveGPSLocation: () => void;
  onStartTrackRecording: () => void;
  onStopTrackRecording: () => void;
};

/**
 * GPS Menu
 */
const MapGpsMenu: React.FunctionComponent<Props> = observer((props) => {
  const { anchorEl, handleClose, onStopTrackRecording, onSaveGPSLocation, onStartTrackRecording } = props;
  if (!anchorEl) return null;

  const gpsModel = appModel.gps;

  return (
    <Menu
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
    >
      {!gpsModel.recordingStarted && [
        (<MenuItem
          key={'saveGPS'}
          onClick={() => {
            onSaveGPSLocation();
            handleClose();
          }}
        >
          {LangKey.GpsSaveGpsLocation.t()}
        </MenuItem>),

        (<MenuItem
          key={'recordingGPS'}
          onClick={onStartTrackRecording}
        >
          {LangKey.GpsStartTrackRecording.t()}
        </MenuItem>),
      ]}
      {gpsModel.recordingStarted && [
        (<MenuItem
          key={'togglePause'}
          onClick={gpsModel.toggleTrackingPause}
        >
          {gpsModel.recordingPaused ? LangKey.GpsRestartTrackRecording.t()
            : LangKey.GpsPauseTrackRecording.t()}
        </MenuItem>),

        (<MenuItem
          key={'stop'}
          onClick={() => {
            gpsModel.stopTracking();
            onStopTrackRecording();
            handleClose();
          }}
        >
          {LangKey.GpsStopTrackRecording.t()}
        </MenuItem>),
      ]}

    </Menu>
  );
});

export default MapGpsMenu;
