import * as React from 'react';
import FormTextField from '../../../uicomponents/forms/FormTextField';
import Button from '../../../uicomponents/Button';
import { Form } from '@simosol/forms/lib/forms';
import * as formRules from '../../../uicomponents/forms/FormRules';
import { DataNodePropNumberWrapper, dataNodePropType, DataNodePropWrapper } from '../../../models/DataNodeUtils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import { DescriptorType } from '@simosol/iptim-data-model';
import ResultSnackBar from '../../app/ResultSnackBar';
import { useMobile } from '../../../uicomponents/Responsive';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import Save from '@mui/icons-material/Save';
import SkipNext from '@mui/icons-material/SkipNext';
import { LangKey } from '../../../LangKey';

type DynamicElementPropEditorProps = {
  item: DataNodePropWrapper | null;
  itemIndex: number;
  className?: string;
  open: boolean;
  viewBack: boolean;
  onClose: (itemIndex: number, click: boolean, back?: boolean) => void;
};

const EditorNumberString = (props: DynamicElementPropEditorProps) => {
  const { onClose, item, itemIndex, open, viewBack } = props;
  if (item === null) return null;

  const isMobile = useMobile();
  const formValue: string = item.valueString;

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'Enter' && open) {
      if (dataNodePropType(item.node, DescriptorType.numeric)) save(false);
    }
    if (item.displayValue) ResultSnackBar.hide();
  };

  const model = React.useMemo(
    () => {
      const rulesArr = [];
      if (dataNodePropType(item.node, DescriptorType.numeric)) {
        const min = item.node.minimum;
        if (min !== undefined) {
          rulesArr.push(formRules.min(min));
        }
        const max = item.node.maximum;
        if (max !== undefined) {
          rulesArr.push(formRules.max(max));
        }
      }
      return new Form({ value: formValue }, { value: rulesArr });
    },
    [item]);

  const save = (click: boolean) => {
    if (!model.validateAll()) return;
    const modelValue = model.getValue('value');
    item.setValueFromString(modelValue);
    if (item.node.descriptor.isRequired) {
      item.displayValue ? onClose(itemIndex, click) : ResultSnackBar.show(LangKey.FormsErrorRequired.t(), true);
    } else onClose(itemIndex, click);
  };

  const saveClick = () => {
    ResultSnackBar.hide();
    save(true);
  };

  const saveNext = () => {
    ResultSnackBar.hide();
    save(false);
  };

  const backPopup = () => {
    model.setValue('value', formValue);
    model.clear();
    onClose(itemIndex, false, true);
  };

  const cancel = () => {
    model.setValue('value', formValue);
    model.clear();
    onClose(itemIndex, true);
  };

  return (
    <Dialog keepMounted open={open} onClose={cancel} onKeyUp={onKeyPress} fullWidth maxWidth={'xs'}>
      <DialogContent>
        <FormTextField
          margin={'dense'}
          field={'value'}
          label={item.displayName}
          model={model}
          fullWidth
          InputProps={
            (item instanceof DataNodePropNumberWrapper && item.unit)
              ? { endAdornment: <InputAdornment position="end">{item.unit}</InputAdornment> }
              : undefined
          }
          autoFocus={true}
        />
      </DialogContent>
      <DialogActions>
        {(dataNodePropType(item.node, DescriptorType.numeric)) && !isMobile
          ? <Typography variant={'caption'}>{LangKey.DynamicPropertyInfoSaveCategoryNext.t()}</Typography>
          : null
        }
        {itemIndex > 0 && viewBack
          ? <IconButton onClick={backPopup} size="large"> <NavigateBefore/> </IconButton>
          : null
        }
        {!isMobile
          ? <Button onClick={cancel} variant={'contained'} color={'inherit'}>
              {isMobile ? <Close/> : LangKey.CommonCancel.t()}
            </Button>
          : null
        }
        <Button onClick={saveClick} variant={'contained'} color={'primary'}>
          {isMobile ? <Save/> : LangKey.CommonSave.t()}
        </Button>
        {dataNodePropType(item.node, DescriptorType.numeric) && isMobile
          ? <Button onClick={saveNext} variant={'contained'} color={'primary'}>{<Save/>}{<SkipNext/>}</Button>
          : null
        }
      </DialogActions>
    </Dialog>
  );
};

export default EditorNumberString;
