import * as React from 'react';
import Projects from '../../models/Projects';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
import SearchField from './SearchField';
import { LangKey } from '../../LangKey';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'sticky',
    top: 72,
    backgroundColor: 'white',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      top: 64,
      flexDirection: 'column',
    },
  },
}));

type Props = {
  model: Projects;
};

const ProjectSearch = (props: Props) => {
  const { model } = props;
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <SearchField
        model={model}
        id={'estateName'}
        options={model.estateNameArray}
        placeholder={LangKey.ProjectsSearchEstate.t()}
        isExpanded
      />
      <SearchField
        model={model}
        id={'estateCode'}
        options={model.estateCodeArray}
        placeholder={LangKey.ProjectsSearchEstateCode.t()}
        isExpanded
      />
      <SearchField
        model={model}
        id={'clients'}
        options={model.estateClientsArray}
        placeholder={LangKey.ProjectsSearchClient.t()}
        isExpanded
      />
    </div>
  );
};

export default ProjectSearch;
