import { LineString, Point, Position } from 'geojson';
import { UtilsMap } from '../../utils/UtilsMap';
import { appModel } from '../App';

export type DMapElement = {
  id: string;
  coords: Position[];
};

export class MapElement {
  private _geometry: Point | LineString;

  constructor(private _data: DMapElement) {
    const coordinates = _data.coords;

    coordinates.length === 1
      ? this._geometry = { coordinates: coordinates[0], type: 'Point' }
      : this._geometry = { coordinates, type: 'LineString' };
  }

  get data() { return this._data; }
  get id() { return this.data.id; }

  get type() {
    return this._geometry.type;
  }

  get coordinatesString() {
    if (this.type === 'Point') {
      return this._geometry.coordinates.join(',');
    } else {
      return UtilsMap.forward3067(this._geometry).coordinates.map((coords) => {
        return !Array.isArray(coords) || (Array.isArray(coords) && typeof coords[0]) !== 'number' ? coords :
          coords
            .map(coord => typeof coord === 'number' ? Math.trunc(coord * 10000) / 10000 : coord)
            .join(',');
      }).join(' ');
    }
  }

  pushDB = () => {
    appModel.db.pushMapElement(this.data);
  }
}
